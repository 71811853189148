import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { fetchUpdatedResults } from "../shared";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    var constrainInput = document.getElementById('user_search_constrain');
    if (constrainInput.value == false || constrainInput.value == null) {
      this.contentTarget.classList.remove("active");
    } else {
      this.contentTarget.classList.add("active");
    }
  }

  toggle() {
    this.contentTarget.classList.toggle("active");

    var constrainInput = document.getElementById('user_search_constrain');
    if (constrainInput.value == false || constrainInput.value == null) {
      constrainInput.value = true
      fetchUpdatedResults();
    } else {
      constrainInput.value = false
    }

    // el.submit()
    // Rails.fire(el, 'submit');
  }

}