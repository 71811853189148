import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    console.log("modal connect()")
  }

  disconnect() {
  }

  close(e) {

    // Turbo.visit("/edit", { action: "replace" })
    console.log(e.target);

    if (e.target.classList.contains('overlay') || e.target.classList.contains('close-modal') ) {
      e.preventDefault()
      e.stopImmediatePropagation()
      var overlay = document.querySelectorAll('.overlay')[0];

      var screen = document.getElementById('screen');
      screen.classList.remove('active');
      overlay.parentNode.removeChild(overlay);
    }
  }

}