import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";
import { fetchUpdatedResults } from "../shared";

export default class extends Controller {
  static targets = ["mapContainer"];
  static values = {
    markers: Object,
    envelope: Array,
  };

  // Lifecycle callbacks
  initialize() {
    this.popup = null;
    this.popUpDist = 8;
    this.popupTimeout = null;
    this.removePopupTimeout = null;

    this.initMapboxStylesAndColors();
    this.initMapboxMap();
  }

  handleCustomEvent(event) {
    console.log("Received custom event in Mapbox controller:", event.detail);

    // this.map?.setAttribute('data-mapbox-markers-value', JSON.stringify(event.detail.markers));
    // this.map?.setAttribute('data-mapbox-envelope-value', JSON.stringify(event.detail.envelope));

    this.markersValue = event.detail.markers;
    this.envelopeValue = event.detail.envelope;

    const boundsInput = document.getElementById("user_search_bounds");
    boundsInput.setAttribute("value", this.map.getBounds().toArray());
    
    // Perform the action you want to execute when the custom event is received
    
  }

  connect() {
    if (window.innerWidth > 640) {
      this.initMapbox();  

      this.handleCustomEvent = this.handleCustomEvent.bind(this);
      document.addEventListener("results:frame-loaded", this.handleCustomEvent);
  
    }
  }

  disconnect() {
    this.map?.remove();
    document.removeEventListener("results:frame-loaded", this.handleCustomEvent);
  }

  onLinkHover(event) {
    // Do something when the link is hovered
    console.log("Link hovered");
  }

  onLinkHoverOut(event) {
    // Do something when the link is no longer hovered
    console.log("Link hover out");
  }

  // Mapbox initialization
  initMapboxStylesAndColors() {
    this.dimColor = "rgba(157, 53, 189, 0.8)";
    this.brightColor = "rgb(157, 53, 189)";
    this.strokeColor = "rgb(255, 255, 255)";
    this.textColor = "rgb(255, 255, 255)";
    this.textFont = ["DIN Offc Pro Medium", "Arial Unicode MS Bold"];
    
    this.mapboxStyle = document.body.classList.contains("prefers-dark-mode")
      ? "mapbox://styles/jpsilvashy-fert/ckkv79vfb39a717rredtixm2r"
      : "mapbox://styles/mapbox/light-v10";
      
  }

  initMapboxMap() {
    this.bounds = [
      [-180, -85],
      [180, 85],
    ];

    mapboxgl.accessToken = this.data.get("api-key");
    this.map = new mapboxgl.Map({
      container: this.element,
      style: this.mapboxStyle,
      bounds: this.bounds,
    });

    // Initialize the popup variable
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: 'mapbox-popup',
      maxWidth: 260,
      offset: { 
        top: [0, this.popUpDist],
        right: [-this.popUpDist, 0],
        bottom: [0, -this.popUpDist],
        left: [this.popUpDist, 0],
      }
    });

  }

  initMapbox() {
    this.initMapboxStylesAndColors();
    this.initMapboxMap();

    this.map.on("load", () => {
      this.setupMapEventsAndMarkers();
    });

    this.map.on("styledata", () => {
      this.addMapLayersWhenReady();
    });
  }

  setupMapEventsAndMarkers(e, envelope) {
    this.layersAdded = false;

    this.map.setPadding({
      top: 50,
      right: 50,
      bottom: 50,
      left: 50,
    });

    this.map.fitBounds([this.envelopeValue[0][1], this.envelopeValue[0][3]], { padding: 50 });
    this.map.resize();

    this.setupMapMarkers();

    this.map.on("dragend", this.redrawMapAfterInteract);
    this.map.on("zoomend", this.redrawMapAfterInteract);
  }

  // Marker handling
  markersValueChanged() {
    console.log("Markers value changed")
    if (this.markersValue && this.map.isStyleLoaded()) {
      this.updateMapMarkers();
    }
  }

  updateMapMarkers() {
    if (this.map.getSource("markers")) {
      this.map.getSource("markers").setData(this.markersValue);
    } else {
      this.map.addSource("markers", {
        type: "geojson",
        data: this.markersValue,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 25,
      });
    }
  }

  setupMapMarkers() {
    this.removeMapMarkers();

    this.updateMapMarkers();

    this.addClusterLayer();
    this.addClusterCountLayer();
    this.addUnclusteredPointLayer();
  }

  removeMapMarkers() {
    this.removeLayer("clusters");
    this.removeLayer("cluster-count");
    this.removeLayer("unclustered-point");
    this.removeSource("markers");
  }

  removeLayer(layerId) {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
  }

  removeSource(sourceId) {
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  addClusterLayer() {
    this.map.addLayer({
      id: "clusters",
      type: "circle",
      source: "markers",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          this.brightColor,
          100,
          this.brightColor,
          750,
          this.brightColor,
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          12,
          5,
          14,
          20,
          16,
        ],

        "circle-stroke-width": 1.5,
        "circle-stroke-color": this.strokeColor,
      },
    });
  }

  addClusterCountLayer() {
    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "markers",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": this.textFont,
        "text-size": 12,
      },
      paint: {
        "text-color": this.textColor,
      },
    });
  }

  addUnclusteredPointLayer() {
    this.map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "markers",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": this.brightColor,
        "circle-radius": 8,
        "circle-stroke-width": 1.5,
        "circle-stroke-color": this.strokeColor,
      },
    });
  }

  addMapLayersWhenReady() {
    if (!this.layersAdded && this.map.isStyleLoaded()) {
      this.setupMapMarkers();

      let labels = [
        'country-label',
        'state-label',
        // 'settlement-label',
        'settlement-subdivision-label',
        'airport-label',
        'poi-label',
        'water-point-label',
        'water-line-label',
        'natural-point-label',
        'natural-line-label',
        'waterway-label',
        // 'road-label'
      ]
    
      let currentLocale = document.querySelector(':root').lang
    
      if (currentLocale == 'zh') {
        currentLocale = 'zh-Hans'
      }
    
      labels.forEach((label) =>
        this.map.setLayoutProperty(label, 'text-field', ['get', `name_${currentLocale}`])
      )
    
      this.map.on('click', 'clusters', function (e) {
        var cluster = e.features[0]
        var currentZoom = Math.floor(this.map.getZoom());
    
        this.map.getSource('markers').getClusterExpansionZoom(cluster.properties.cluster_id, function(err, zoom) {
          if (err) return;
    
          this.map.easeTo({
            center: cluster.geometry.coordinates,
            zoom: zoom
          })
        })
      });

      this.map.on('mouseleave', 'unclustered-point', (e) => {
        this.map.getCanvas().style.cursor = 'grab';
      
        if (this.popup && this.popup.isOpen() && this.popup.getElement()) {
          this.popupTimeout = setTimeout(() => {
            this.popup.getElement().classList.add('mapbox-popup-transition');
  
            this.removePopupTimeout = setTimeout(() => {
              this.popup.getElement().classList.remove('mapbox-popup-transition');
              this.popup.remove();
            }, 300);
          }, 300);
        }
      });
      
      let activeUnclusteredPoint = String.new;

      this.map.on('mouseenter', 'unclustered-point', (e) => {
        this.map.getCanvas().style.cursor = 'pointer';
      
        // if (activeUnclusteredPoint == e.features[0].properties.id) {
        //   console.log('[popup] mouseenter', 'same-point')
        //   return
        // }
      
        if (this.popup && this.popup.isOpen() && this.popup.getElement()) {
          clearTimeout(this.popupTimeout);
          clearTimeout(this.removePopupTimeout);  

          this.popup.getElement().classList.remove('mapbox-popup-transition');
         };

        activeUnclusteredPoint = e.features[0].properties.id
         
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const cardProperties = e.features[0].properties;
         
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
      
        let htmlContent = `<div class='mapbox-popup-card-wrapper' data-controller='mapbox-location' data-mapbox-location-id='${cardProperties.id}' data-mapbox-location-map-card-url='${cardProperties.map_card_url}' data-mapbox-location-service-provider-url='${cardProperties.service_provider_url}'>
            <a href='${cardProperties.service_provider_url}'>   
              <span class='mapbox-popup-card-title'>${cardProperties.name}</span>
              <span class='mapbox-popup-card-subtitle'>${cardProperties.location}</span>
              <span class='mapbox-popup-card-content'>
                <span class='popup-inner-content'>
                  <span class='popup-doctor-meta'>
                    <span class='doctor-count loading-wrapper'>
                      <span class='loading-text'>loading</span>
                    </span>
                  </span>
                </span>
              </span>
            </a>
        </div>`;
         
        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup.setLngLat(coordinates).setHTML(htmlContent).addTo(this.map);
      });

      this.map.on('click', 'unclustered-point', function (e) {
      });

      this.layersAdded = true;
    }
  }

  // Event handling
  redrawMapAfterInteract = () => {
    const boundsInput = document.getElementById("user_search_bounds");
    boundsInput.setAttribute("value", this.map.getBounds().toArray());

    const updateOnInteractBtn = document.getElementById("update-on-interact");
    updateOnInteractBtn.classList.add("visible");

    const constrainInput = document.getElementById("user_search_constrain");
    if (constrainInput.value === "true") {
      fetchUpdatedResults();
    }
  };

  // Marker utilities
  addMarker(marker) {
    const markerElement = document.createElement('div');
    markerElement.className = 'marker';
    new mapboxgl.Marker(markerElement).setLngLat([marker.lng, marker.lat]).addTo(window.map);
  }

}
