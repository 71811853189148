import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  popupContent(cardProperties) {
    console.log("cardProperties:", cardProperties)
    
    let span = document.createElement("span");
    span.classList.add('popup-inner-content')

    let renderDoctorImages = cardProperties.doctor_photos.map((url) => `<img class='mini-doctor-avatar' src='${url}'>`).join();
    
    let renderDoctors = null;
    if (cardProperties.doctor_photos.length > 0) {
      renderDoctors = `<span class='doctor-avatars'>${renderDoctorImages}</span>`;
    } else {
      renderDoctors = ""
    }

    let renderRating = null;
    if (cardProperties.rating != null) {
      renderRating = `<span class='popup-rating review-score-single score-high'>${cardProperties.rating}</span>`
    } else {
      renderRating = ""
    }

    let renderReviewCount = null;
    if (cardProperties.reviews_count != '0 reviews') {
      renderReviewCount = `<span class='popup-review-count'>${cardProperties.reviews_count}</span>`
    } else {
      renderReviewCount = ""
    }

    span.innerHTML = `
      <span class='popup-doctor-meta'>
        ${renderDoctors}
        <span class='doctor-count'>${cardProperties.doctors_count}</span>
      </span>
      <span class='popup-review-meta'>
        ${renderReviewCount}
        ${renderRating}  
      </span>
    `;

    return span.outerHTML;
  }

  initialize() {
    this.active = false;
  }

  connect() {
    if (this.active == true) {
      return
    }

    this.active = true
    
    Rails.ajax({
      url: this.data.get("mapCardUrl"),
      type: "get",
      data: {}, 
      success: (data) => {
        this.element.querySelector('.mapbox-popup-card-content').innerHTML = this.popupContent(data)
      },
      error: (error) => {
        console.error("Error:", error);
      },
    });
  }

  disconnect() {
  }

}

