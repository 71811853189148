import { Controller } from "@hotwired/stimulus"

import * as d3 from "d3"

function roundedRect(x, y, width, height, radius) {
  return "M" + (x + radius) + "," + y +
    "h" + (width - radius*2) +
    "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius +
    "v" + (height - 2 * radius) +
    "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius +
    "h" + (radius*2 - width) +
    "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius +
    "v" + (2*radius - height) +
    "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
    "z";
}

export default class extends Controller {
  static targets = ["output"]

  connect() {
    let preferredColor

    if (document.body.classList.contains('prefers-dark-mode')) {
      preferredColor = 'dark'
    } else {
      preferredColor = 'light'
    }

    let data = JSON.parse(this.element.dataset.clinicDataChartData);
    let displayAttributes = JSON.parse(this.element.dataset.clinicDataChartDisplayAttributes);

    if (this.element.innerHTML.length == 0) {
      this.drawChart(preferredColor, data, displayAttributes)
    }

  }

  disconnect() {
    // this.data.element.innerHTML = ""
  }

  drawChart(preferredColor, data, displayAttributes) {
    let translations = JSON.parse(this.data.get("translations"))

    let keys = [
      translations["this_clinic"] || 'This Clinic', 
      translations["national_average"] || 'National Average'
    ]

    let pKey = translations["age_group"] || "Age Group"
    let k1 = translations["national"] || 'National'
    let k2 = translations["singleton_live_birth_rate"] || 'Singleton Live Birth Rate'
    let v1 = translations["success_rate"] || "Success Rate"
    let v2 = translations["female_age"] || "Female Age"

    let colors = ['rgba(68, 84, 149, 0.82)', 'rgba(68, 84, 149, 0.22)'];
    
    // 
    let colorScheme = {}

    if (preferredColor == 'dark') {
      colorScheme['text'] = 'rgba(255,255,255,0.8)'
    } else {
      colorScheme['text'] = '#666666'
    }

    // Set up chart
    let containerWidth = d3.select(this.data.element)
      .style('width')
      .slice(0, -2)

    let margin = { 
      top: 10, 
      right: 10, 
      bottom: 34, 
      left: 44 
    };

    let width = containerWidth - margin.left - margin.right;
    let height = 200 - margin.top - margin.bottom;

    let aspect = width / height;

    let svg = d3.select(this.data.element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")")
      .on("resize", function () {
        let targetWidth = svg.node().getBoundingClientRect().width;
        svg.attr("width", targetWidth);
        svg.attr("height", targetWidth / aspect);
      });

    data.forEach(function (v) { delete v["Priority"] });
    
    // let groups = d3.map(data, function (d) { return (d[pKey]) }).keys()

    let groups = data.map(function (d) { return String(d[pKey]); });
    // let subgroups = Object.keys(data[0]).filter(function(key) {
    //   return key !== "Age Group";
    // });



    let subgroups = Object.keys(data[0])
    // subgroups.shift(1);

    let color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(colors)

    let legend = svg.selectAll(".legend")
      .data(keys)
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", function (d, i) { return "translate(0," + i * 20 + ")"; });

    legend.append("circle")
      .attr("cx", width - 2)
      .attr("cy", 9)
      .attr("r", 7)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", color);

    legend.append("text")
      .attr("x", width - 14)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .style("font-size", "12px")
      .style('fill', colorScheme['text'])
      .text(function (d) { return d; });

    // Add X axis
    let x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.4]);

    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x-axis")
      .call(d3.axisBottom(x).tickSize(0));

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -2 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("font-size", "13px")
      .style("text-anchor", "middle")
      .style('fill', colorScheme['text'])
      .text(v1);

    svg.append("text")
      .style("text-anchor", "middle")
      .style("font-size", "13px")
      .attr("y", height + 32)
      .attr("x", (width / 2) )
      .style('fill', colorScheme['text'])
      .text(v2);

    let maxAll = d3.max(data, function (d) { return +d[k1]; });
    let maxThis = d3.max(data, function (d) { return +d[k2]; });
    let maxY = d3.max([maxAll, maxThis]) * 100

    // Add Y axis
    let y = d3.scaleLinear()
      .domain([0, maxY])
      .range([height, 0]);

    // let formatPercent = d3.format(".0%");
    svg.append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(y)
        // .tickFormat(formatPercent)
        .tickValues(y.ticks(5))
      );

    // Another scale for subgroup position?
    let xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      .data(data)
      .enter().append("g")
      .attr("transform", function (d) { 
        return "translate(" + x(d[pKey]) + ",0)";
      })
      .selectAll("rect")
      .data(function (d) { 
        return subgroups.map(function (key) { 
          return { key: key, value: d[key] }; 
        }); 
      })
      .enter().append("rect")
        .attr("x", function (d) { 
          return xSubgroup(d.key); 
        })
        .attr("y", function (d) { 
          if (typeof d.value == 'number') {
            return y(d.value * 100); 
          }
        })
        .attr("rx", function (d) { 
          return 6; 
        })        
        .attr("ry", function (d) { 
          return 6; 
        })        
        .attr("width", xSubgroup.bandwidth() - 3)
        .attr("height", function (d) { 
          if (typeof d.value == 'number') {
            return height - y(d.value * 100); 
          }
        })
        .attr("fill", function (d) { 
          return color(d.key); 
        })  
        .on('mouseover', function (e) {
          // console.log(e)
        })
        .on('mouseout', function (e) {
          // console.log(e)
        })
        .on('mousemove', function (e) {
          // console.log(e)
        });


  }
}
