import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    markers: Object,
    envelope: Array,
  };

  connect() {
    document.body.classList.remove("loading");

    this.dispatchCustomEvent();
  }

  dispatchCustomEvent() {
    const event = new CustomEvent("results:frame-loaded", {
      bubbles: true,
      detail: { 
        envelope: this.envelopeValue,
        markers: this.markersValue
      },
    });
    this.element.dispatchEvent(event);
  }

}