import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "completed", "checkbox", "filter", "menuToggle", "sort", "menu", 'searchType']

  initialize() {
    this.currentMenu;
    this.currentSearchType;
  }

  connect() {
  }

  toggleLocationSearch(event) {
    let autocompleteLocation = document.getElementById('autocomplete-location')
    let autocompleteQuery = document.getElementById('autocomplete-query')

    if (autocompleteLocation.classList.contains('active')) {
      autocompleteQuery.classList.add('active')      
      autocompleteLocation.classList.remove('active')
    } else {
      autocompleteQuery.classList.remove('active')      
      autocompleteLocation.classList.add('active')      
    }

    setTimeout(function () { document.getElementById("query").focus() }, 70);
  }

  closeModalMenu(event) {
    let screen = document.getElementById('screen')
    if (screen.classList.contains('active')) {
      screen.classList.remove('active')
      
      document.body.classList.remove('modal-menu-open')

      this.menuTargets.forEach( (target) => target.classList.remove('active') )
    }
  }

  toggleMenuVisibility(event) {
    let menu = event.currentTarget.dataset.userSearchMenu
    this.currentMenu = this.menuTargets.find((element) => element.dataset.userSearchMenu == menu)

    let screen = document.getElementById('screen')
    if (screen.classList.contains('active')) {
      screen.classList.remove('active')
      this.currentMenu.classList.remove('active')

      document.body.classList.remove('modal-menu-open')
    } else {
      screen.classList.add('active')
      this.currentMenu.classList.add('active')

      document.body.classList.add('modal-menu-open')     
    }
  }

  selectFilter(event) {
    this.filter = event.target.getAttribute('name');
    this.renderFilters();
  }

  set filter(name) {
    this.data.set("filter", name);
  }

  get filter() {
    if (this.data.has("filter")) {
      return this.data.get("filter");
    } else {
      return;
    }
  }

  renderFilters() {
    this.filterElements.forEach(filter => {
      if (filter.getAttribute('name') === this.filter) {
        filter.classList.toggle("selected")
      }
    });
  }

  get filterElements() {
    return this.filterTargets;
  }

  get menuElements() {
    return this.menuTargets;
  }

  // blockUserInput() {
  //   document.body.classList.add("loading");
  //   document.querySelectorAll('input[type*=text]')
  //     .forEach(x => x.setAttribute("readonly", "readonly"))
  // }

  updateAndSearch(e) {
    e.stopImmediatePropagation()
    var checkbox = document.getElementsByName(e.target.dataset.userSearchAttr)[0]

    if (checkbox.value == 'false') {
      checkbox.checked = true
      checkbox.value = 'true'
    } else {
      checkbox.checked = false
      checkbox.value = 'false'
    }
    
    Rails.fire(this.element, 'submit')

    Array.from(this.formTarget.elements).forEach(formElement => formElement.disabled = true);

    this.blockUserInput()

    // fetchUpdatedResults()
  }

  updateSortAndSearch(e) {
    e.stopImmediatePropagation();
    var sortInput = document.getElementsByName("sort")[0];
    sortInput.value = e.target.dataset.userSortAttr;
  
    this.blockUserInput();
  
    // Submit the form with an AJAX request
    Rails.fire(this.formTarget, "submit");
  }  

  updateScopeAndSearch(e) {
    e.stopImmediatePropagation()
    var scopeInput = document.getElementsByName('scope')[0]
    scopeInput.value = e.target.dataset.userScopeAttr

    var elems = document.querySelectorAll(".scope-item");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });

    e.target.classList.add("active")
    Rails.fire(this.element, 'submit')

    this.blockUserInput()
  }

}