window.autocompleteRequestWaiting = false

import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'result' ]

  initialize() {
  }

  get resultElements() {
    return this.resultTargets;
  }

  connect() {
    var data = this.data
    var resultElements = this.resultElements;

    document.addEventListener('autocomplete.change', function(e) {
      e.stopImmediatePropagation();

      document.body.classList.add("loading");

      document.querySelectorAll('input[type*=text]')
        .forEach(x => x.setAttribute("readonly", "readonly"))

      const { value, textValue } = e.detail
      const urlParts = value.split('/').filter(function (el) { if (el != "") return el; });

      if (document.body.classList.contains('controller-blog-posts') == true) {
        return Turbo.visit(value, { action: "replace" })
      }

      if (urlParts[0] == 'search') {
        Rails.ajax({
          type: "get",
          url: `${value}.js`,
          data: "",
          success: function (data) { },
          error: function (data) { }
        })
      } else {
        location.assign(value);
      }

    })
  }

  disconnect() {
    document.removeEventListener("autocomplete.change", function(e) { });
  }
}

