import { Controller } from "@hotwired/stimulus"

import * as d3 from "d3"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    let preferredColor

    if (document.body.classList.contains('prefers-dark-mode')) {
      preferredColor = 'dark'
    } else {
      preferredColor = 'light'
    }

    if (window.innerWidth > 640) {
      if (this.element.innerHTML.length == 0) {
        this.drawChart(preferredColor)
      } 
    }
  }

  disconnect() {
    // this.data.element.innerHTML = ""
  }

  drawChart(preferredColor) {
    let colorScheme = {}

    if (preferredColor == 'dark') {
      colorScheme['text'] = 'rgba(255,255,255,0.8)'
    } else {
      colorScheme['text'] = '#666666'
    }

    // Set up chart
    let containerWidth = d3.select(this.data.element)
      .style('width')
      .slice(0, -2)

    let margin = { top: 10, right: 10, bottom: 34, left: 44 },
      width = containerWidth - margin.left - margin.right,
      height = 200 - margin.top - margin.bottom;

    let aspect = width / height;

    let translations = JSON.parse(this.data.get("translations"))
    
    let svg = d3.select(this.data.element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")")
      .on("resize", function () {
        var targetWidth = svg.node().getBoundingClientRect().width;
        svg.attr("width", targetWidth);
        svg.attr("height", targetWidth / aspect);
      });

    let data = JSON.parse(this.element.dataset.clinicSingleChartData);
    data.forEach(function (v) { delete v["Priority"] });
    
    // let groups = d3.map(data, function (d) { return (d["Age Group"]) }).keys();
    let groups = data.map(function (d) { return String(d["Age Group"]); });
    let subgroups = Object.keys(data[0]).filter(function(key) {
      return key !== "Age Group";
    });

    let colors = ['rgba(68, 84, 149, 0.82)', 'rgba(68, 84, 149, 0.22)'];

    let color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(colors)

    let keys = [
      translations["this_clinic"] || 'This Clinic', 
      translations["national_average"] || 'National Average'
    ]

    let legend = svg.selectAll(".legend")
      .data(keys)
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", function (d, i) { return "translate(10," + ((i * 20) + -10) + ")"; });
    
    legend.append("circle")
      .attr("cx", width - 12)
      .attr("cy", 9)
      .attr("r", 7)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", function(d, i) { return colors[i]; });
    
    legend.append("text")
      .attr("x", width - 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .style("font-size", "12px")
      .style('fill', colorScheme['text'])
      .text(function (d) { return d; });

    // Add X axis
    let x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2]);

    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x-axis")
      .call(d3.axisBottom(x).tickSize(0))
      .call(x)
      .selectAll("text")  
      .style("text-anchor", "end")
      .attr("dy", "1em")

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("font-size", "12px")
      .style("text-anchor", "middle")
      .style('fill', colorScheme['text'])
      .text(translations["success_rate"] || 'Success Rate');    

    svg.append("text")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .attr("y", height + 30)
      .attr("x", (width / 2) )
      .style('fill', colorScheme['text'])
      .text(translations["female_age"] || 'Female Age');

    let maxNational = d3.max(data, function (d) { return +d['National']; });
    let maxThis = d3.max(data, function (d) { return +d['Singleton Live Birth Rate']; });
    let maxY = d3.max([maxNational, maxThis]) * 100 

    // Add Y axis
    let y = d3.scaleLinear()
      .domain([0, maxY])
      .range([height, 0]);

    svg.append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(y));

    // Another scale for subgroup position?
    let xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.2])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      .data(data)
      .enter().append("g")
      .attr("transform", function (d) { 
        return "translate(" + x(d['Age Group']) + ",0)"; 
      })
      .selectAll("rect")
      .data(function (d) { 
        return subgroups.map(function (key) { 
          return { key: key, value: d[key] }; 
        }); 
      })
      .enter().append("rect")
        .attr("x", function (d) { 
          return xSubgroup(d.key); 
        })

        .attr("rx", function (d) { 
          return 4; 
        })        

        // .attr("ry", function (d) { 
        //   return 0; 
        // })        

        // .attr("d", function(d) {
        //   return self.rightRoundedRect(x(0), y(d.name), x(d.value) - x(0), y.rangeBand(), 10);
        // })

        .attr("y", function (d) { 
          if (typeof d.value == 'number') {
            return y(d.value * 100); 
          }
        })
        .attr("width", xSubgroup.bandwidth())
        .attr("height", function (d) { 
          if (typeof d.value == 'number') {
            return height - y(d.value * 100); 
          }
        })
        .attr("fill", function (d) { 
          return color(d.key); 
        });
  

  }

}
