import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  initialize() {
  }

  connect() {
    console.log('subscription');

    let appearance = {}

    if (document.body.classList.contains('prefers-dark-mode')) {
      appearance = {
        theme: 'night',
      }
    } else {
      appearance = {}
    }

    const stripe = Stripe('pk_test_51EZ23xJeeEyrLes9mmjtHfjFoJPDavXw3jRg7gxKQiSZjzdCeXXOHTfZaJh9BiuzxZn1Y4pWzaTbXt8eg8HC7BkH00wCZ6YxNK');
    const options = {
      clientSecret: this.formTarget.dataset.secret,
      appearance: appearance,
    };

    
    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
    const elements = stripe.elements(options);
    
    // Create and mount the Payment Element
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');

    const form = document.getElementById('payment-form');

    // form.addEventListener('submit', function (ev) {
    //   ev.preventDefault();
    // });

    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.formTarget.dataset.redirectTo,
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)

        debugger;

        const messageContainer = document.querySelector('#error-message');
        messageContainer.innerHTML = '<div>' + error.message  + '</div>';
        
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    });
  }

  disconnect() {
  }

}

