import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "customerId", "priceId", "billingName", "publishableKey"]

  initialize() {
    this.stripe = Stripe(this.publishableKeyTarget.value);

    let style = {}    
    if (document.body.classList.contains('prefers-dark-mode')) {
      style = {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: '500',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#ffffff',
          },
          '::placeholder': {
            color: '#71767c',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        }
      }
    }

    this.elements = this.stripe.elements({});
    this.card = this.elements.create('card',{
      style: style 
    });

  }

  connect() {
    this.card.mount('#card-element');

    console.log(this.customerIdTarget.value)

    const form = document.getElementById('payment-form');
    let displayError = this.displayError;

    this.card.on('change', function (event) {
      displayError(event);
    });

    // form.addEventListener('submit', function (ev) {
    //   ev.preventDefault();
    // });

    form.addEventListener('submit', async (event) => {
      event.preventDefault();
  
      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.billingNameTarget.value,
          },
        })
        .then((result) => {
          if (result.error) {
            this.displayError(result);
          } else {
            this.createSubscription({
              customerId: this.customerIdTarget.value,
              paymentMethodId: result.paymentMethod.id,
              priceId: this.priceIdTarget.value,
            });
          }
        });

    });
  }

  createSubscription(stripeItems) {
    fetch('/clients/billing', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ subscription: stripeItems})
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.status == 'active') {
          Turbo.visit('/clients/billing/' + data.id);
        }
      })
      .catch(error => console.error("Error:", error));
  }

  handlePaymentThatRequiresCustomerAction(subscription, invoice, priceId, paymentMethodId, isRetry) {
    debugger;
  }

  handleRequiresPaymentMethod(subscription, paymentMethodId, priceId) {
    debugger;
  }

  displayError(event) {
    const messageContainer = document.querySelector('#card-element-errors');

    if (event.error) {
      messageContainer.innerHTML = '<div>' + event.error.message  + '</div>';
    } else {
      messageContainer.innerHTML = '';
    }
  }


  disconnect() {
    this.card.destroy();

    let frames = this.stripe._controller._frames

    for(let i = 0; i < frames.length; i++){
      frames[i].removeAllListeners()
      frames[i]._removeAllListeners()
      frames[i]._iframe.remove()
    }

    this.stripe._controller._controllerFrame.removeAllListeners()
    this.stripe._controller._controllerFrame._removeAllListeners()
    this.stripe._controller._controllerFrame._iframe.remove()

    // manually remove
    let stripeIframes = [
      // document.querySelectorAll('[name^=__privateStripeMetricsController]'),
      document.querySelectorAll('[name^=__privateStripeController]'),
      document.querySelectorAll('[name^=__privateStripeFrame]'),
    ];

    stripeIframes.forEach(iframes => iframes.forEach(iframe => {
        iframe.parentNode.removeChild(iframe);
    }));

  }

}

