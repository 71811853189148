import { Controller } from "@hotwired/stimulus"

import * as d3 from "d3"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    var preferredColor

    if (document.body.classList.contains('prefers-dark-mode')) {
      preferredColor = 'dark'
    } else {
      preferredColor = 'light'
    }

    var data = JSON.parse(this.element.dataset.clinicReviewsChartData);
    var displayAttributes = {}; //JSON.parse(this.element.dataset.clinicReviewsChartDisplayAttributes);

    // setTimeout(() => {
    //   this.drawChart(preferredColor, [data.score], displayAttributes)
    // }, 500);

    if (this.element.innerHTML.length == 0) {
      this.drawChart(preferredColor, [data.score], displayAttributes)
    }

  }

  disconnect() {
    // this.data.element.innerHTML = ""
  }

  drawChart(preferredColor, data, displayAttributes) {
    
    // 
    var colorScheme = {}

    if (preferredColor == 'dark') {
      colorScheme['text'] = '#ffffff'
    } else {
      colorScheme['text'] = '#000000'
    }

    // Set up chart
    var containerWidth = d3.select(this.data.element)
      .style('width')
      .slice(0, -2)
      
    var svg = d3.select(this.data.element).append("svg")
      .attr("width", '100%')
      .attr("height", 6);
      
    const c = d3.color("rgba(68, 84, 149, 0.22)");
    const w = d3.color("rgba(255, 255, 240, 0.82)");

    var chart = svg.selectAll("g")
      .data(data)
      .enter().append("rect")
      .attr("x", function (d) { 
        return 0; 
      })
      .attr("rx", function (d) { 
        return 4; 
      })        
      .attr("ry", function (d) { 
        return 4; 
      })        
      .attr("y", function (d) { 
        return 0; 
      })
      .attr("width", function (d) { 
        return '100%'; 
      })
      .attr("height", function (d) { 
        return 6; 
      })
      .attr("fill",c)

    var chart2 = svg.selectAll("g")
      .data(data)
      .enter().append("rect")
      .attr("x", function (d) { 
        return 0; 
      })
      .attr("rx", function (d) { 
        return 4; 
      })        
      .attr("ry", function (d) { 
        return 4; 
      })        
      .attr("y", function (d) { 
        return 0; 
      })
      .attr("width", function (d) { 
        return d*100 + '%'; 
      })
      .attr("height", function (d) { 
        return 6; 
      })
      .attr("fill",'#5dc75d')
      
  }

}
