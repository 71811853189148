import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = [ "tooltip" ]

  connect() {
    this.popperInstance = createPopper(this.element, this.tooltipTarget, {
      placement: 'bottom',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
      ]
    });
  }

  show(event) {
    this.tooltipTarget.classList.add("tooltip-visible");
    this.popperInstance.update();
  }

  hide(event) {
    this.tooltipTarget.classList.remove("tooltip-visible");
  }

  
  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
