import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectInput" ];

  initialize() {
    // console.log(this.selectInputTarget);
  }

  update(event) {
    this.element.setAttribute("action", this.selectInputTarget.value);
    // Turbo.visit(this.selectInputTarget.value);
    this.element.submit();
  }
}
