import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'conditional', 'wrapper']

  connect() {
    this.update()
  }

  update () {
    if (this.inputTarget.value == "") {
      this.wrapperTarget.classList.remove('hidden')
    } else {
      this.wrapperTarget.classList.add('hidden')
    }

    this.conditionalTarget.value = ''

  }
  

}
