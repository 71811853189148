import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

const OT = require('@opentok/client');

dayjs.extend(relativeTime)
dayjs.extend(calendar)

export default class extends Controller {
  static targets = [ 'messages', 'form', 'textarea', 'demarcation', 'vonage', 'video', 'controls' ]
  
  initialize() {
    this.apiKey = this.data.get('api-key')
    this.sessionId = this.data.get('session-id')
    this.token = this.data.get('token')
  }

  initVonage () {
    this.initializeSession()
  }

  connect() {
    if (this.hasTextareaTarget == true) {
      this.connectToUnreadMessagesChannel();
      this.setupTextarea();
      this.resizeTextarea();
      this.observeKeyPresses();
      this.scrollDown();
      this.setDateDemarcations();
    }
  }

  setupTextarea() {
    this.textareaTarget.style.height = `${this.textareaTarget.scrollHeight - 12}px`
  }

  resizeTextarea() {
    this.textareaTarget.style.height = 'auto'
    this.textareaTarget.style.height = `${this.textareaTarget.scrollHeight - 12}px`
    this.textareaTarget.style.overflow = 'hidden';
  }

  observeKeyPresses() {
    let form = this.formTarget;
    this.textareaTarget.addEventListener("keydown", function(event) {
      if (event.keyCode==13 && !event.shiftKey){
        Turbo.navigator.submitForm(form)
        event.preventDefault()
      } 
    });
  }

  scrollDown() {
    var element = document.getElementById('chat-wrapper');

    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }
  }
  
  reset(event) {
    this.formTarget.reset();
    this.setupTextarea();
    this.scrollDown();
  }

  connectToUnreadMessagesChannel() {
    console.log("connectToUnreadMessagesChannel")
    // let applicationController = this;
    this.unreadMessagesSubscription = consumer.subscriptions.create({
      channel: "UnreadMessagesChannel",
      // id: this.data.get("userId")
    }, {
      connected() {
        console.log('unreadMessagesSubscription connected()')
        // Called when the subscription is ready for use on the server
      },
      disconnected() {
        this.unsubscribe();
        // Called when the subscription has been terminated by the server
      },
      received(data) {
        console.log(data)
      }
    });
  }

  disconnect() {
    if (this.unreadMessagesSubscription != undefined ) {
      this.unreadMessagesSubscription.unsubscribe();
    }
  }

  setDateDemarcations() {
    this.demarcationTargets.forEach((el, i) => {
      let x = dayjs(el.dataset.time).calendar(null, {
        sameDay: '[Today]', 
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YYYY'
      })

      el.innerHTML = x
    })
  }

  initializeSession() {
    this.videoTarget.classList.add('active')
    this.controlsTarget.classList.add('active')
    this.session = OT.initSession(this.apiKey, this.sessionId)
    this.session.on('streamCreated', this.streamCreated.bind(this))

    this.publisher = OT.initPublisher(this.vonageTarget, {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      name: this.data.get("name"),
    }, this.handleError.bind(this))

    this.session.connect(this.token, this.streamConnected.bind(this))
  }

  endVideoSession() {
    this.controlsTarget.classList.remove('active')
    this.videoTarget.classList.remove('active')
    this.session.disconnect()
  }

  streamConnected(error) {
    if (error) {
      this.handleError(error)
    } else {
      this.session.publish(this.publisher, this.handleError.bind(this))
    }
  }

  streamCreated(event) {
    this.session.subscribe(event.stream, this.vonageTarget, {
      insertMode: 'append',
      width: '100%',
      height: '100%',
    }, this.handleError.bind(this))
  }

  handleError(error) {
    if (error) {
      console.error(error.message)
    }
  }

  disconnect() {
    if (this.session) {
      this.endVideoSession()
    }
  }

}
