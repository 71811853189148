import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let cleave = new Cleave('.telephone-number-mask', {
      phone: true,
      phoneRegionCode: 'US'
    });
  }
}

