import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    console.log("initialize");
  }

  connect() {
    console.log("connect");
  }

  onPostSuccess(event) {
    console.log("onPostSuccess!");
  }

  update() {
    console.log("update!");
    Rails.fire(this.element, 'submit');
  }
}