import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'isSubmitting' ]
  static targets = [ 'submitButton', 'image', 'pane' ]
  static values = { isSubmitting: Boolean }

  initialize() {
    if (this.isLoaded) {
      this.imageLoaded()
    }
  }

  connect() {    
    this.paneTarget.classList.add('loading')
  }

  updatePreviewImageUrl(data, imageTarget) {
    imageTarget.setAttribute('src', data.url)
  }

  update() {
    this.buildImageUrl();
  }

  imageLoaded() {
    this.paneTarget.classList.remove('loading')
    this.imageTarget.classList.remove('loading')
  }

  get isLoaded() {
    return this.element.complete
  }

  buildImageUrl() {
    this.paneTarget.classList.add('loading')

    let formData = Object.fromEntries(new FormData(document.querySelector('.new_shareable_image')).entries());    
    const queryString = new URLSearchParams(formData).toString();
     
    let imageUpdateFunc = this.updatePreviewImageUrl
    let imageTarget = this.imageTarget

    imageTarget.classList.add('loading')

    Rails.ajax({
      url: this.data.get('url'),
      type: "get",
      data: queryString, 
      success: function (data) {
        imageUpdateFunc(data, imageTarget)
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });

  }

  disconnect() {
  }

}

