import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  share(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    if (navigator.share != undefined) {
      navigator.share({
        title: document.querySelector("meta[property='og:title']").content,
        url: document.location.href
      })
    } else {
      console.log('cannot share')
    }
  }

  connect() {
    
  }

  disconnect() {
  }

}

