import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import * as d3 from "d3"

export default class extends Controller {
  static targets = [
    'chartContainer',
    'optionServiceProvider',
    'optionDoctors',
    'optionData',
    // 'optionWebsiteReferrals',
    // 'optionPhoneReferrals',
    // 'optionSocialReferrals',
  ]

  connect() {
    let preferredColor
    let drawChartNg = this.drawChartNg

    if (document.body.classList.contains('prefers-dark-mode')) {
      preferredColor = 'dark'
    } else {
      preferredColor = 'light'
    }

    this.getChartData(function(err, data) {
      drawChartNg(preferredColor, data)
    });

    // if (this.element.innerHTML.length < 30) {
    // }    
  }

  getChartData(callback) {
    const checkboxOptionValues = this.checkboxValues();
    const checkboxOptions = new URLSearchParams();
    Object.keys(checkboxOptionValues).forEach(key => checkboxOptions.append(key, checkboxOptionValues[key]));

    Rails.ajax({
      type: "get",
      url: `/clients/analytics/${this.chartContainerTarget.dataset.userId}.js?${checkboxOptions}`,
      success: function(response) {
        callback(null, response)
      },
      error: function(response) {
        callback(response)
      }
    })

  }

  checkboxValues() {
    const data = {
      optionServiceProvider: this.optionServiceProviderTarget.checked,
      optionDoctors: this.optionDoctorsTarget.checked,
      optionData: this.optionDataTarget.checked,
      // optionWebsiteReferrals: this.optionWebsiteReferralsTarget.checked,
      // optionPhoneReferrals: this.optionPhoneReferralsTarget.checked,
      // optionSocialReferrals: this.optionSocialReferralsTarget.checked,
    }

    return data
  }

  redrawChart() {
    let drawChartNg = this.drawChartNg
    this.getChartData(function(err, data) {
      drawChartNg(null, data)
    });
  }

  toggleOptionServiceProvider() {
    this.redrawChart()
  }

  toggleOptionDoctors() {
    this.redrawChart()
  }

  toggleOptionLocations() {
    this.redrawChart()
  }

  toggleOptionWebsiteReferrals() {
    this.redrawChart()
  }

  toggleOptionPhoneReferrals() {
    this.redrawChart()
  }

  toggleOptionSocialReferrals() {
    this.redrawChart()
  }

  toggleOptionData() {
    this.redrawChart()
  }

  disconnect() {
    // this.data.element.innerHTML = ""
  }

  drawChartNg(preferredColor, data) {
    // console.log(preferredColor, data)

    let element = document.getElementById('analytics-chart-container');
    let formatDate = d3.timeFormat("%-m/%-d/%Y");
    let tooltip = d3.select("body").append("div")	
      .attr("class", "chart-tooltip")				
      .style("opacity", 0);

    let aggregateData = []

    for (const [category, categoryData] of Object.entries(data)) {
      Object.entries(categoryData).forEach(function(d) {
        d[1].forEach(function(d) {
          d.ts    = d3.isoParse(d.ts);
          d.count = +d.count;

          aggregateData.push(d)
        });
      });
    }

    // set the dimensions and margins of the graph
    let margin = {top: 20, right: 20, bottom: 30, left: 30},
      width = element.offsetWidth - margin.left - margin.right,
      height = element.offsetHeight - margin.top - margin.bottom;

    // set the ranges
    let x = d3.scaleTime().range([0, width]);
    let y = d3.scaleLinear().range([height, 0]);

    // define the line
    let valueline = d3.line()
      .x(function(d) { return x(d.ts); })
      .y(function(d) { return y(d.count); });

    let div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    d3.select(element).selectAll('svg').remove();

    let svg = d3.select(element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    // Scale the range of the data
    x.domain(d3.extent(aggregateData, function(d) { return d.ts; }));
    y.domain([0, d3.max(aggregateData, function(d) { return d.count; })]);

    // Add the X Axis
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Add the Y Axis
    svg.append("g")
      .call(d3.axisLeft(y));

    for (const [category, categoryData] of Object.entries(data)) {
      Object.entries(categoryData).forEach(function(r) {
        svg.append("path")
          .data([r[1]])
          .attr("class", "line line-" + r[0])
          .attr("d", valueline);

        svg.selectAll("dot")
          .data(r[1]) //.filter(function(d){ return d.count > 0; })
          .enter().append("circle")
          .attr("class", "circle circle-" + r[0])
          // .attr("r", function(d) {  if (d.count > 0) return 5; })
          .attr("r", 5)
          .attr("cx", function(d) { return x(d.ts); })
          .attr("cy", function(d) { return y(d.count); }).on("mouseover", function(d) {		
            tooltip.transition()		
              .duration(200)		
              .style("opacity", .9);		
            tooltip.html(formatDate(d.ts) + "<br/>" + r[0] + ":" + d.count)	
              .style("left", (d3.event.pageX - 97) + "px")		
              .style("top", (d3.event.pageY - 100) + "px");	
            })
            .on("mousemove", function(){
              tooltip
                .style("left",(d3.event.pageX - 97)+"px")
                .style("top", (d3.event.pageY - 100)+"px")
              })		
            .on("mouseout", function(d) {		
              tooltip.transition()		
                .duration(500)		
                .style("opacity", 0);	
            });
    

        })
    };


  }

}
