import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {

  }

  connect() {
    console.log('video-playback connect()')
  }


  disconnect() {
    this.element.remove()
  }

}