import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["salaryDisplayOption", "salaryApproximateField", "salaryRangeFields"]

	connect() {
		this.toggleSalaryFields()
	}

	toggleSalaryFields() {
		let selectedOptionRadio = this.salaryDisplayOptionTargets.find(radio => radio.checked)
		let selectedOption = selectedOptionRadio ? selectedOptionRadio.value : "not_visible"

		console.log("toggleSalaryFields", this.salaryDisplayOptionTargets)


		this.salaryApproximateFieldTarget.hidden = (selectedOption !== "approximate")
		this.salaryRangeFieldsTarget.hidden = (selectedOption !== "range")
	}
}
