import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    if (this.element.dataset.userId == document.body.dataset.viewer) {
      this.element.classList.add('sent-by-me')
    } else {
      this.element.classList.add('sent-by-them')
    }

    if (this.element.dataset.wasStreamed == 'true') {
      this.scrollDown()
    }
  }

  scrollDown() {
    var element = document.getElementById('chat-wrapper');
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  disconnect() {
  }

}