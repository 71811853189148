import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import { fetchUpdatedResults } from "../shared";

export default class extends Controller {
  initialize() {
  }

  loading() {
    document.body.classList.add("loading");
  }

  submitForm(event) {
    event.preventDefault(); // This prevents the default form submission

    const searchForm = document.querySelector(".search-form");
    const itemsSearchForm = document.querySelector(".items-search");
  
    let searchFormData = new FormData(searchForm);
    let itemsSearchFormData = new FormData(itemsSearchForm);
  
    // Merge itemsSearchFormData into searchFormData
    for (var pair of itemsSearchFormData.entries()) {
      searchFormData.append(pair[0], pair[1]);
    }
  
    // Create a new form element to submit the merged data
    const mergedForm = document.createElement("form");
    mergedForm.setAttribute("method", searchForm.method);
    mergedForm.setAttribute("action", searchForm.action);
    // mergedForm.setAttribute("data-remote", "true");
  
    // Add the merged form data as hidden input fields
    for (var pair of searchFormData.entries()) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", pair[0]);
      input.setAttribute("value", pair[1]);
      mergedForm.appendChild(input);
    }
  
    // Add the new form to the DOM and submit it using Rails UJS
    document.body.appendChild(mergedForm);
  
    console.log("[submitting form]", mergedForm)
  
    Rails.fire(mergedForm, "submit");
  
    // Remove the new form from the DOM
    document.body.removeChild(mergedForm);
  
  }

  connect() {
  }

  disconnect() {
    document.body.classList.remove("loading");
  }

}

