import { Controller } from "@hotwired/stimulus";
import mapboxgl from 'mapbox-gl';

export default class extends Controller {  
  initialize() {
  }
  
  disconnect() {
  }

  connect() {
    // import("moment").then(moment => {
    //   this.moment = moment.default;
    // });

    setTimeout(() => {
      this.initMapbox()
    }, 500);

    // if (screen.width > 600) {
    // }
  }

  initMapbox() {
    const mapboxApiKey = this.data.get('api-key')
    // const markers = JSON.parse(this.data.get('markers'))

    mapboxgl.accessToken = mapboxApiKey

    // var envelope = JSON.parse(this.data.get('envelope'));
    // var bounds = [envelope[0][1], envelope[0][3]]

    // window.map = new mapboxgl.Map({
    //   container: this.element,
    //   style: 'mapbox://styles/mapbox/light-v10',
    //   bounds: bounds,
    //   // maxBounds: bounds
    // })

    // 
    const markers = JSON.parse(this.data.get('markers'))
    const envelope = JSON.parse(this.data.get('envelope'));
    // const bounds = [envelope[0][1], envelope[0][3]]

    // infer color scheme from body tag
    const bodyTag = document.body
    if (bodyTag.classList.contains('prefers-dark-mode')) {
      var style = 'mapbox://styles/jpsilvashy-fert/ckkv79vfb39a717rredtixm2r'
      // var style = 'mapbox://styles/mapbox/dark-v10'
    } else {
      // var style = 'mapbox://styles/jpsilvashy-fert/ckkv77iwx39ga17p8n4cxj0vu'
      var style = 'mapbox://styles/mapbox/light-v10'
    }

    if (markers.features.length > 1) {
      window.map = new mapboxgl.Map({
        container: this.element,
        style: style,
        // bounds: bounds
      })
    } else {
      var marker = markers.features[0]

      // console.log(marker)

      window.map = new mapboxgl.Map({
        container: this.element,
        style: style,
        // bounds: bounds,
        center: marker.geometry.coordinates,
        zoom: 13
      })
    }


    // 
    // let language = 'fr'
    // let textLabels = [
    //   'country-label',
    //   'state-label',
    //   'settlement-label',
    //   'settlement-subdivision-label',
    //   'airport-label',
    //   'poi-label',
    //   'water-point-label',
    //   'water-line-label',
    //   'natural-point-label',
    //   'natural-line-label',
    //   'waterway-label',
    //   'road-label'
    // ]

    // textLabels.forEach(label => map.setLayoutProperty(label, 'text-field', [ 'get', 'name_' + language ]) )


    // window.map.setPadding({
    //   top: 5,
    //   right: 5,
    //   bottom: 5,
    //   left: 5
    // })

    window.map.on('load', function(e) {
      window.setupMapMarkers(e.target, markers);
      // e.target.fitBounds([
      //   envelope[0][1],
      //   envelope[0][3]
      // ], { padding: 5 });

      // let textLabels = [
      //   'country-label',
      //   'state-label',
      //   // 'settlement-label',
      //   // 'settlement-subdivision-label',
      //   // 'airport-label',
      //   // 'poi-label',
      //   // 'water-point-label',
      //   // 'water-line-label',
      //   // 'natural-point-label',
      //   // 'natural-line-label',
      //   'waterway-label',
      //   // 'road-label'
      // ]
  
      // textLabels.forEach(label => map.setLayoutProperty(label, 'text-field', [ 'get', 'name_' + mapboxLocale ]) )
  

      // window.map.resize();
    });

    // window.map.on('dragend', function () {
    //   console.log("Map drag:", window.map.getBounds().toArray());
    // });

    // window.map.on('zoomend', function () {
    //   console.log("Map zoom:", window.map.getBounds().toArray());
    // });
  }

  addMarker(marker) {
    new mapboxgl.Marker().setLngLat([marker.lng, marker.lat]).addTo(window.map)
  }

}

