import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import consumer from "../channels/consumer";
import { setCookie, getCookie, eraseCookie } from "../shared";

import Cleave from 'cleave.js';

let shouldCloseUserNav = false;


export default class extends Controller {
  static targets = ["pageMenu", "searchMenu", "menu", "baseContainer", "innerPageMenu", 'analyticsDashboard', 'mapContainer', 'openInnerPageButton'];

  initialize() {
    this.currentMenu;
    this.lastScroll;
    // ahoy.trackAll();
  }

  frameLoaded() {
    console.log("[Application] Results Turbo Frame loaded");
    this.dispatchCustomEvent();
  }

  dispatchCustomEvent() {
    const event = new CustomEvent("results:frame-loaded", {
      bubbles: true,
      detail: { message: "Results frame loaded" },
    });
    this.element.dispatchEvent(event);
  }

  connect() {

    console.log("[Application] Stimulus controller connected");

    if (document.documentElement.hasAttribute("data-turbo-preview") == false ) {
      this.connectToPresenceChannel()
    }
      
    this.lastScroll = 0;

    // const driver = new Driver();
    // driver.highlight({
    //   element: '#autocomplete-query',
    //   popover: {
    //     title: 'Title for the Popover',
    //     description: 'Description for it',
    //   }
    // });

    this.initMenuStickyScroll()
    this.initDarkMode()

    var t = this;
    window.addEventListener('resize', function(event) {
      t.initializeSidebarState();
    }, true);

    this.initializeSidebarState();

    // if (document.body.hasAttribute('data-viewer') && document.body.getAttribute('data-viewer') != '') {
    // }
  }

  initializeSidebarState() {
    let sidebar = document.getElementsByClassName('page-sidebar')[0]
    if (window.innerWidth > 640 && sidebar != undefined && document.body.classList.contains('open-default-sidebar-when-wide') == true ){
      this.openMenu(sidebar)
    } else {
      this.closeAllMenus()
    }
  }

  isScrollingUp() {
    document.body.classList.remove("scroll-down");
    document.body.classList.add("scroll-up");
  }

  isScrollingDown() {
    document.body.classList.remove("scroll-up");
    document.body.classList.add("scroll-down");
  }

  throttleCheckScroll(fn, wait, context) {
    var time = Date.now();
    return function () {
      if ((time + wait - Date.now()) < 0) {
        fn(context);
        time = Date.now();
      }
    }
  }

  checkScroll(context) {
    let t = context;
    const currentScroll = window.pageYOffset;

    // Dont do the shrinky header thing if the search results are visible
    var itemResults = document.getElementById("item-results");
    if (itemResults != undefined && itemResults.hidden == false) {
      return
    }

    if (currentScroll > t.lastScroll && !document.body.classList.contains("scroll-down")) {
      t.isScrollingDown()
    } else if (currentScroll <= t.lastScroll) {
      t.isScrollingUp()
    }

    if (currentScroll <= 40) {
      t.isScrollingUp()
      document.body.classList.add("menu-at-top");
      document.body.classList.remove("scroll-down");
      document.body.classList.remove("scroll-up");
    } else {
      document.body.classList.remove("menu-at-top");
    }

    t.lastScroll = currentScroll;
  }

  initMenuStickyScroll() {
    document.body.classList.add("menu-at-top");
    window.addEventListener('scroll', this.throttleCheckScroll(this.checkScroll, 50, this));
  }

  get menuElements() {
    return this.menuTargets;
  }

  closeAllMenus() {
    this.menuElements.forEach(menu => {
      this.closeMenu(menu);
    })

    if (document.getElementById('menu-screen')) {
      document.getElementById('menu-screen').classList.remove('active')
    }
  
    if (document.getElementById('screen')) {
      document.getElementById('screen').classList.remove('active')
    }
  
    
    document.body.classList.remove("menu-open");
    // document.documentElement.classList.remove("menu-open");

    this.currentMenu = undefined;
  }

  delayCloseAllMenus() {
    let t = this;
    setTimeout(function () { t.closeAllMenus() }, 70);
  }

  openMenu(menu) {
    menu.classList.add('active')

    if (menu.dataset.menu == 'search' && document.getElementById("query")) {
      document.getElementById("query").focus()
    }
  }

  closeMenu(menu) {
    menu.classList.remove('active')

    if (menu.dataset.menu == 'search' && document.getElementById("query")) {
      document.getElementById("query").blur()
    }
  }

  setDarkMode(cookie) {
    document.body.classList.add('prefers-dark-mode');
    setCookie('_prefers_color_scheme', 'dark', { 'max-age': 3600 });
  }

  setLightMode(cookie) {
    document.body.classList.remove('prefers-dark-mode');
    setCookie('_prefers_color_scheme', 'light', {'max-age': 3600 });
  }

  setColorSchemePreference(e) {
    var cookie = getCookie('_prefers_color_scheme')

    if (cookie != undefined && cookie == 'dark') {
      this.setLightMode(cookie)
    } else if (true) {
      this.setDarkMode(cookie)
    }
  }

  toggleDarkMode(e) {
    this.setColorSchemePreference()
    // Turbo.clearCache();
    // Turbo.cache.clear();
    
    // redraw charts
    if (document.getElementById('analytics-chart-container')) {
      this.analyticsDashboardTarget.dispatchEvent(new CustomEvent("redrawChart"));
    }

    // redraw maps
    if (document.getElementById('map')) {
      this.mapContainerTarget.dispatchEvent(new CustomEvent("redrawMap"));
    }
  }

  //
  toggleMenu(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    let menu = event.currentTarget.dataset.menu;
    let newMenu = this.menuTargets.find((element) => element.dataset.menu == menu);
    let otherMenus = this.menuTargets.filter((element) => element.dataset.menu != menu);

    otherMenus.forEach(otherMenu => {
      this.closeMenu(otherMenu) 
    })

    if ((this.currentMenu != undefined)) {
      if (this.currentMenu == newMenu) {
        this.closeMenu(this.currentMenu);
        document.body.classList.remove("menu-open");
        // document.documentElement.classList.remove("menu-open")
        this.currentMenu = undefined;
      } else {
        this.closeMenu(this.currentMenu);
        this.openMenu(newMenu);
        this.currentMenu = newMenu;
      }
    } else {
      this.openMenu(newMenu);
      document.body.classList.add("menu-open");
      // document.documentElement.classList.add("menu-open")
      this.currentMenu = newMenu;

      if (event.currentTarget.href) {
        Rails.ajax({
          type: "get",
          url: event.currentTarget.href + '.js',
          // data: "",
          success: function (data) { },
          error: function (data) { }
        })
      }
    }
  }

  openInnerMenu(event) {
    event.preventDefault();
    event.stopImmediatePropagation();    
    
    // remove chart content
    if (document.getElementById('analytics-chart-container')) {
      document.getElementById('analytics-chart-container').innerHTML = '';
    }

    this.openInnerPageButtonTarget.classList.add('open');
    this.innerPageMenuTarget.classList.add('open');
    document.getElementById('help-toggle').classList.add('open');

    setCookie('_inner_page_menu', 'open', { 'max-age': 3600 });    

    // trigger redrawchart
    if (document.getElementById('analytics-chart-container')) {
      this.analyticsDashboardTarget.dispatchEvent(new CustomEvent("redrawChart"));
    }
  }

  closeInnerMenu(event) {
    event.preventDefault();
    event.stopImmediatePropagation();    

    // remove chart content
    if (document.getElementById('analytics-chart-container')) {
      document.getElementById('analytics-chart-container').innerHTML = '';
    }

    this.openInnerPageButtonTarget.classList.remove('open');
    this.innerPageMenuTarget.classList.remove('open');
    document.getElementById('help-toggle').classList.remove('open');

    eraseCookie('_inner_page_menu');

    // trigger redrawchart
    if (document.getElementById('analytics-chart-container')) {
      this.analyticsDashboardTarget.dispatchEvent(new CustomEvent("redrawChart"));
    }

  }

  hideUserNav(event) {
    if (shouldCloseUserNav == true && document.getElementById("user-nav-menu") !== null) {
      document.getElementById("user-nav-menu").classList.remove('visible')
    }
  }

  toggleUserMenu(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  showUserNav(event) {
    shouldCloseUserNav = false

    if (document.getElementById("user-nav-menu") !== null) {
      document.getElementById("user-nav-menu").classList.add('visible')
    }
  }

  toggleMessagingEnabled(event) {
    
  }

  queueHideUserNav(event) {
    shouldCloseUserNav = true
    setTimeout(() => this.hideUserNav(), 1600)
  }

  connectToPresenceChannel() {
    // let applicationController = this;
    this.subscription = consumer.subscriptions.create({
      channel: "PresenceChannel",
      // id: this.data.get("userId")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },
      disconnected() {
        // this.subscription.unsubscribe();
      },
      received(data) {
        if (data.status == 'online') {
          document.querySelector(`[data-user-id='${data.id}']`).classList.add('is-present')
        } else if (data.status == 'offline') {
          document.querySelector(`[data-user-id='${data.id}']`).classList.remove('is-present')
        }
      }
    });
  }

  disconnect() {
    if (this.subscription != undefined ) {
      this.subscription.unsubscribe();
    }
  }

  get menu() {
    if (this.data.has("menu")) {
      return this.data.get("menu");
    } else {
      return;
    }
  }

  set menu(name) {
    this.data.set("menu", name);
  }

  initDarkMode() {
    var cookie = getCookie('_prefers_color_scheme')
    const bodyTag = document.body

    // Detect color scheme from browser
    if (cookie == undefined) {
      // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      //   bodyTag.classList.add('prefers-dark-mode');
      //   setCookie('_prefers_color_scheme', 'dark', { 'max-age': 3600 });

      // } else {
      //   bodyTag.classList.remove('prefers-dark-mode');
      //   setCookie('_prefers_color_scheme', 'light', { 'max-age': 3600 });
      // }

      setCookie('_prefers_color_scheme', 'light', { 'max-age': 3600 });

    } else {
      if (cookie == 'dark') {
        bodyTag.classList.add('prefers-dark-mode');
      } else if (true) {
        bodyTag.classList.remove('prefers-dark-mode');
      }
    }
  }
}
