import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    setTimeout(() => {
      this.element.classList.add('fade')

      setTimeout(() => {
        this.element.remove()
      }, 1000);    
  
    }, 3500);    
  }

  disconnect() {
  }

}

