// The rest is fontawesome
import { library, dom, config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false;
config.mutateApproach = 'sync';
config.keepOriginalSource = false;

import {
  // faBusAlt,
  // faFileCertificate,
  // faFillDrip,
  // faBatteryFull,
  // faBatteryQuarter,
  // faCactus,
  // faCrow,
  // faCampfire,
  // faBirthdayCake,
  // faEar,
  // faCorn,
  // faCookieBite,
  // faHatWizard,
  // faFlaskPotion,
  // faWandMagic,
  // faScarf,
  // faBookSpells,
  faUserCircle,
  faMapMarker,
  faSlidersHSquare,
  faSortUp,
  faCircle,
  faImagePolaroid,
  faEdit,
  faHourglassClock,
  faStarSharpHalfStroke,
  faAddressCard,
  faBuildings,
  faLifeRing,
  faInbox,
  faUserCog,
  faSignOutAlt,
  faInboxFull,
  faRightFromBracket,
  faChartColumn,
  faPaperPlane,
  faPlug,
  faUsersGear,
  faUserGear,
  faLocationPlus,
  faUserDoctor,
  faUserDoctorMessage,
  faThumbsUp,
  faPhotoFilm,
  faChevronRight,
  faExternalLink,
  faPencil,
  faEye,
  faCreditCard,
  faQrcode,
  faBrush,
  faEnvelopesBulk,
  faChartMixed,
  faPersonDressBurst,
  faBadgeCheck,
  faSealQuestion,
  faArrowsToEye,
  faCogs,
  faPersonCirclePlus,
  faSpinner,
  faUserDoctorHairLong,
  faSparkles
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';

import {
  faCaretDown,
  faCaretLeft,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExternalLinkAlt,
  faSpinnerThird,
  faBars,
  faSearch,
  faArrowRight,
  faDotCircle,
  faShare,
  faEnvelope,
  faPhone,
  faAngleRight,
  faGear,
  faLink,
  faBellSlash,
  faStar
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCheck,
  faCheckSquare,
  faSquare,
  faXmark
} from '@fortawesome/pro-light-svg-icons';

library.add(
  // faBusAlt,
  // faFileCertificate,
  // faFillDrip,
  // faBatteryFull,
  // faBatteryQuarter,
  // faCactus,
  // faCrow,
  // faCampfire,
  // faBirthdayCake,
  // faEar,
  // faCorn,
  // faCookieBite,
  // faHatWizard,
  // faFlaskPotion,
  // faWandMagic,
  // faScarf,
  // faBookSpells,
  faSquare,
  faSpinner,
  faCheckSquare,
  faCheck,
  faCaretDown,
  faCaretLeft,
  faExternalLinkAlt,
  faSearch,
  faSpinnerThird,
  faBars,
  faUserCircle,
  faMapMarker,
  faSlidersHSquare,
  faSortUp,
  faArrowRight,
  faChevronLeft,
  faCircle,
  faDotCircle,
  faEnvelope,
  faPhone,
  faShare,
  faLifeRing,
  faInbox,
  faUserCog,
  faSignOutAlt,
  faTwitter,
  faFacebook,
  faInstagram,
  faTiktok,
  faLinkedin,
  faYoutube,
  faLink,
  faAngleRight,
  faImagePolaroid,
  faEdit,
  faHourglassClock,
  faStarSharpHalfStroke,
  faAddressCard,
  faBuildings,
  faInboxFull,
  faRightFromBracket,
  faChartColumn,
  faPaperPlane,
  faPlug,
  faUsersGear,
  faUserGear,
  faLocationPlus,
  faUserDoctor,
  faThumbsUp,
  faPhotoFilm,
  faExternalLink,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faPencil,
  faEye,
  faCreditCard,
  faGear,
  faBellSlash,
  faUserDoctorMessage,
  faQrcode,
  faBrush,
  faEnvelopesBulk,
  faChartMixed,
  faPersonDressBurst,
  faBadgeCheck,
  faSealQuestion,
  faXmark,
  faStar,
  faArrowsToEye,
  faCogs,
  faPersonCirclePlus,
  faUserDoctorHairLong,
  faSparkles
)

dom.watch()


