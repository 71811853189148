import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectInput"];

  initialize() {
    // console.log(this.selectInputTargets);
  }

  connect() {
    var modal = document.querySelectorAll('.modal-actions-wide')[0];
    modal.classList.add('very-wide')
  }

  clearActiveOptionGroup() {
    document.body.querySelectorAll(".form-option-group").forEach(function (el) {
      el.classList.remove('active');
    });

    document.querySelector('.modal-actions-wide').classList.remove('very-wide');
  }

  advanceToStep2() {
    this.clearActiveOptionGroup()
    document.body.querySelector(".form-option-group-2").classList.add('active');
    sendGoogleAnalyticsEvent('profile_select_age', {});
  }

  advanceToStep3() {
    this.clearActiveOptionGroup()
    document.body.querySelector(".form-option-group-3").classList.add('active');
    sendGoogleAnalyticsEvent('profile_select_username', {});
  }

  update(event) {
    const el = event.currentTarget;
    
    this.selectInputTargets.forEach(element => {
      element.classList.remove('active')
    });

    el.classList.add("active");

    document.getElementById("user_profile_fertility_strategy_id").setAttribute("value", el.dataset.value);

    this.advanceToStep2()
  }

}


