import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";
import { record } from 'rrweb';

export default class extends Controller {
  connect() {
    // console.log("Connected to rrweb_recorder controller");
    // this.startRecording();
    // document.addEventListener("turbo:load", this.startRecording);
  }

  disconnect() {
    // if (this.stopRecording) {
    //   this.stopRecording();
    // }
    // document.removeEventListener("turbo:load", this.startRecording);
  }

  startRecording = () => {
    // Ensure any previous recording is stopped before starting a new one
    if (this.stopRecording) {
      this.stopRecording();
    }

		this.stopRecording = record({
			emit: (event) => {
				this.handleEvent(event);
			},
		});
		this.startSendingEvents();
  }

  handleEvent(event) {
    // Implement your logic to handle the recorded event
    // For example, pushing it to an array or directly sending to the server
    if (!this.events) {
      this.events = [];
    }
    this.events.push(event);
  }

  startSendingEvents() {
    // Ensure events are sent periodically to the server
    if (!this.hasStartedSendingEvents) {
      this.hasStartedSendingEvents = true;
      this.interval = setInterval(() => this.sendEventsToServer(), 10000);
    }
  }

  sendEventsToServer() {
    if (this.events && this.events.length) {
      const body = JSON.stringify({ events: this.events });
      const csrfToken = Rails.csrfToken();

      fetch('/rrweb_events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: body,
      })
      .then(() => {
        // Clear events after successful transmission
        this.events = [];
      })
      .catch((error) => {
        console.error("Failed to send rrweb events:", error);
      });
    }
  }
}
