import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [ "selectInput" ];

  initialize() {
    // console.log(this.selectInputTarget);
  }

  delete(event) {
    let confirmed = confirm("Are you sure?")

    if (!confirmed) {
      event.preventDefault();
    }

  }

}
