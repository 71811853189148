import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  hideModal() {
    this.element.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.element.innerHTML = ""
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }
}