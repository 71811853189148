import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import { fetchUpdatedResults } from "../shared";

export default class extends Controller {
  static targets = ["filter", "checkbox", "icon"]

  connect() {
  }

  toggleFilter(event) {
    const filterElement = event.currentTarget;
    const inputElement = filterElement.querySelector("input[type='checkbox']");
    
    // Toggle the checkbox state
    inputElement.checked = !inputElement.checked;
        
    fetchUpdatedResults();
  }

  // blockUserInput() {
  //   document.body.classList.add("loading");
  //   document.querySelectorAll('input[type*=text]')
  //     .forEach(x => x.setAttribute("readonly", "readonly"))
  // }


  set filter(name) {
    this.data.set("filter", name);
  }

  get filter() {
    if (this.data.has("filter")) {
      return this.data.get("filter");
    } else {
      return;
    }
  }

  renderFilters() {
    // Locate and loop through all filters
    const filterElements = document.querySelectorAll(".filter-checkbox");
    filterElements.forEach((filterElement) => {
      
      // Add event listener for click events
      filterElement.addEventListener("click", (event) => {
        // Prevent default behavior to avoid submitting the form immediately
        event.preventDefault();
        
        // Call toggleFilter with the event
        this.toggleFilter(event);
      });
    });
  }

  get filterElements() {
    return this.filterTargets;
  }
  
}