import { Controller } from "@hotwired/stimulus"

import * as d3 from "d3"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    var preferredColor

    if (document.body.classList.contains('prefers-dark-mode')) {
      preferredColor = 'dark'
    } else {
      preferredColor = 'light'
    }

    var data = JSON.parse(this.element.dataset.clinicReviewsChartData);
    var displayAttributes =  {} //JSON.parse(this.element.dataset.clinicReviewsChartDisplayAttributes);

    this.drawChart(preferredColor, [data.score], displayAttributes)

    if (this.element.innerHTML.length == 0) {
      this.drawChart(preferredColor, [data.score], displayAttributes)
    } else {
      // debugger;
    }

    // setTimeout(() => {
    //   this.drawChart(preferredColor, [data.score], displayAttributes)
    // }, 250);

  }

  disconnect() {
    // this.data.element.innerHTML = ""
  }

  drawChart(preferredColor, data, displayAttributes) {
    
    // 
    var colorScheme = {}

    if (preferredColor == 'dark') {
      colorScheme['text'] = '#ffffff'
    } else {
      colorScheme['text'] = '#000000'
    }

    // Set up chart
    var containerWidth = d3.select(this.data.element)
      .style('width')
      .slice(0, -2)

    var margin = { 
      top: 0, 
      right: 0, 
      bottom: 0, 
      left: 40
    };

    var width = containerWidth - margin.left - margin.right;
    var height = 60 - margin.top - margin.bottom;

    var aspect = width / height;

    var backgroundArc = d3.arc()
      .innerRadius(19)
      .outerRadius(24)
      .startAngle(0)
      .endAngle(Math.PI*2);
    
    var mainArc = d3.arc()
      .innerRadius(19)
      .outerRadius(24)
      .cornerRadius(10)
      .startAngle(0)
      .endAngle(function(d) { return d/10*Math.PI* 2 });
            
    var svg = d3.select(this.data.element).append("svg")
      .attr("width", 48)
      .attr("height", 48);
      
    var charts = svg.selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform",function(d,i) { 
        return "translate(" + (i*100) + 24 + ",24)";
      });
      
    const c = d3.color("rgba(68, 84, 149, 0.22)");
    // c.opacity = 0.2;

    charts.append("path")
      .attr("d", backgroundArc)
      .attr("fill",c)
    
    charts.append("path")
      .attr("d", mainArc) 
      .attr("fill","#5dc75d")

      // const w = d3.color("rgba(255, 255, 240, 0.82)");
    const w = d3.color("#5dc75d");

    charts
      .data(data)
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr("fill", w)
      .style("font-size", "16px")
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .text(function(d) {
        return d;
      });


  }

}
