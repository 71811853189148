import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {}
  
  openCalendlyPopUp(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    Calendly.initPopupWidget({url: this.data.get('url')});
  }

  disconnect() {}

}