import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"

export default class extends Controller {
  
  initialize() {
    console.log('choices')
  }

  //   debounce(func, wait, immediate) {
  //   var timeout;
  //   return function() {
  //     var context = this, args = arguments;
  //     var later = function() {
  //       timeout = null;
  //       if (!immediate) func.apply(context, args);
  //     };
  //     var callNow = immediate && !timeout;
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //     if (callNow) func.apply(context, args);
  //   };
  // }

  connect() {
    const choices = new Choices(this.element, {
      removeItemButton: true
    })

    // const debounce = (fn, delay) => {
    //   let inDebounce = null;
    //   return args => {
    //     clearTimeout(inDebounce);
    //     inDebounce = setTimeout(() => fn(args), delay);
    //   }
    // }  

    // choices.passedElement.element.addEventListener('search', debounce(async e => {
    //     console.log(e.detail)

    //     const data = fetch(`${e.srcElement.dataset.url}?query=${encodeURIComponent(e.detail.value)}`)
    //     this.choices.setChoices(data, this.dataset.value || 'value', this.dataset.label || 'label', true)
    //   }, 1000)
    // )    
        
  }

  disconnect() {
  }

}