import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [form]
  // submit() {
  //   formTarget.requestSubmit();
  // }

  initialize() {
  }

  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const service_provider_id = urlParams.get('service_provider_id')

    console.log(service_provider_id)

    const frame = document.getElementById('modal');
    frame.src = '/compare/add?service_provider_id=' + service_provider_id;
    frame.reload();

  }

  disconnect() {
  }

}

