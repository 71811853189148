import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "text" ];

  initialize() {
    var linkWidth = parseInt(this.textTarget.offsetWidth);
    var svg = this.createSVG(linkWidth);
    this.textTarget.appendChild(svg)
  }

  connect() {
  }

  disconnect() {
  }

  createSVG(linkWidth) {

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", linkWidth);
    svg.setAttribute("height", "20");

    // debugger;

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    var pathD = this.randomizePath(linkWidth);

    path.setAttribute("d", pathD);
    path.setAttribute("fill", "transparent");
    // path.setAttribute("stroke", "rgb(157, 53, 189)");
    path.setAttribute("stroke-width", "7");
    path.setAttribute("stroke-linecap", "round");

    svg.appendChild(path);

    // debugger;

    return svg;
  }

  randomizePath(linkWidth) {
    var moveYMin = 5;
    var moveYMax = 12;
    var curveXMin = 20;
    var curveXMax = linkWidth; /* Width of the link */
    var curveYMin = 5;
    // var curveYMax = 20;
    var curveYMax = linkWidth * 0.1;

    var endYMin = 5;
    var endYMax = 10;
    var moveY = Math.floor(Math.random() * (moveYMax - moveYMin)) + moveYMin;
    var curveX = Math.floor(Math.random() * (curveXMax - curveXMin)) + curveXMin;
    var curveY = Math.floor(Math.random() * (curveYMax - curveYMin)) + curveYMin;
    var endY = Math.floor(Math.random() * (endYMax - endYMin)) + endYMin;

    var newPath = `M5 ${moveY} Q ${curveX} ${curveY} ${linkWidth - 7} ${endY}`;

    return newPath;
  }

  disconnect() {
    this.textTarget.getElementsByTagName('svg')[0].remove();
  }

}

