import "@hotwired/turbo-rails"
import "./icons"
import "./controllers"
import "./sentry"
import "trix"
import "@rails/actiontext"

console.log("🍍 FertilitySpace (we're hiring, email jobs@fertilityspace.io) 🍍")

// window.onerror = function (message, source, lineno, colno, error) {
//   if (
//     message.includes("Cannot read properties of undefined (reading 'get')") &&
//     source.includes("pauseable_placement.js")
//   ) {
//     console.warn("Mapbox error caught and suppressed:", message);
//     return true;
//   }
//   return false;
// };

document.addEventListener("turbo:before-fetch-request", (event) => {
  event.detail.fetchOptions.headers["X-Turbo-Nonce"] = document.querySelector("meta[name='csp-nonce']")?.content
})

document.addEventListener("turbo:before-cache", () => {
  document.querySelectorAll("script[nonce]").forEach((element) => {
    element.setAttribute("nonce", element.nonce);
  })
})

// persist initial stripe iFrame DOM Object across turbo AJAX page requests
// https://github.com/hotwired/turbo/issues/270#issuecomment-1068130327
let stripeIFrameQuery = 'iframe[src^="https://js.stripe.com"]';
document.addEventListener('turbo:before-render', function (event) {
  const stripeIFrame = document.querySelector(stripeIFrameQuery);
  const newStripeIFrame = event.detail.newBody.querySelector(stripeIFrameQuery);
  if (stripeIFrame && !newStripeIFrame){
    event.detail.newBody.appendChild(stripeIFrame)
  }
});

Turbo.setConfirmMethod(() => {
  let dialog = document.getElementById('turbo-confirm')
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener("close", () => {
      resolve(dialog.returnValue == 'confirm')
    }, { once: true})
  })
})